import MenuItems from "./MenuItems";
import NavbarIcons from "./NavbarIcons";
import { Container, Navbar } from "react-bootstrap";
import { getHallPalace } from "../../api/services/media-center-api/hall/king-hall&palaceNavs";
import { useTranslation } from "react-i18next";
import { getEservicesMenuItems } from "../../api/services/Digital-content-api/Eservices-home-api";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NavbarIconsMobileScreen from './NavbarIconsMobileScreen';
import { CheckToken } from "../../api/services/checkToken-api";
const AllParams = new URLSearchParams(location.search);

const NavbarContainer = ({ Links }) => {
   const { t, i18n } = useTranslation();
   const [collapseMenu, setCollapseMenu] = useState(false);

   let langSelected = localStorage.getItem("i18nextLng");
      if (langSelected === "en-US" || langSelected === "") {
         localStorage.setItem("i18nextLng", "ar");
         window.location.reload()
      }
      const [isLogin , setIsLogin] =useState(false);
      let URLStore =process.env.REACT_APP_DARAH_STORE_URL;

// let URLStore = "http://localhost:3001/";

      useEffect(()=>{
        if ( isLogin) {
//  URLStore = "http://localhost:3001/";
          window.location.reload();
        }

            CheckToken().then(res => {                   
            if(res.message ===  true){
            setIsLogin(false);
            } 
            }).catch(err => {  
            setIsLogin(true);
            localStorage.removeItem("token");
            localStorage.removeItem("isRemember");
            sessionStorage.removeItem("token");                
             })
      },[])
const tokenStore = AllParams.get('token');
const isRememberStore = AllParams.get('isRemember') || localStorage.getItem("isRemember");

function saveToken(token, remember) {
          if (remember) {
          localStorage.setItem("token", token);
          sessionStorage.removeItem("token");
          }else {
            sessionStorage.setItem("token", token);
            localStorage.removeItem("token");
          }
        }

        if (!localStorage.getItem("token") && !sessionStorage.getItem("token")) {        
         if (tokenStore) {
            const cleanedToken = tokenStore.replace(/\?\/isRemember=(true|false)/, '');            
            saveToken(cleanedToken, isRememberStore === 'true');
            
          }
          } else {
            let token = isRememberStore === 'true' ? localStorage.getItem("token") : sessionStorage.getItem("token");
            let isRemember = localStorage.getItem("isRemember");
            if (token && (localStorage.getItem("token") || sessionStorage.getItem("token"))) {
               URLStore = `${URLStore}?token=${token}&isRemember=${isRemember}`;
            }else{
//  URLStore = "http://localhost:3001/";
 URLStore = process.env.REACT_APP_DARAH_STORE_URL;

            }
            AllParams.delete("token");
            AllParams.delete("isRemember");
            const newUrl = `${window.location.pathname}?${AllParams.toString()}`;
            window.history.replaceState(null, "", newUrl);
        } 
   const [menuItems] = useState([
      {
         title: t("header.home"),
         url: "/"
      },
      {
         title: `${t("header.about.about_darah")}`,
         url: "#!",
         submenu: [
            {
               title: `${t("header.about.about_darah")}`,
               url: "/about-us",
            },
            {
               title: `${t("header.about.king_and_darah")}`,
               url: "/king-and-darah",
            },
            {
               title: `${t("header.about.darah_board")}`,
               url: "/board",
            },
            // {
            //    title: `${t("header.about.departments_centers")}`,
            //    url: "/departments",
            // },
            {
               title: `${t("header.about.membership")}`,
               url: "/membership",
            },
         ],
      },
      {
         title: `${t("header.services.title")}`,
         url: "/e-services",
         submenu: [
            {
               title: `${t("header.services.all_services")}`,
               url: "/e-services",
            },
         ],
      },
      // {
      //    title: `${t("header.digital_content.title")}`,
      //    url: "#!",
      //    submenu: [
      //       {
      //          title: `${t('header.digital_content.all')}`,
      //          url: "/digital-content/all",
      //       },
      //    ],
      // },
      {
         title: `${t("DarahJournal")}`,
         url: process.env.REACT_APP_DARAH_JOURNAL_URL,
         external_url: true,
      },
      {
         title: `${t("header.projects")}`,
         url: "/projects",
      },
      {
         title: `${t("header.media_center.title")}`,
         url: "#!",
         submenu: [
            {
               title: `${t("header.media_center.news")}`,
               url: "/news",
            },
            {
               title: `${t("nav_items.events")}`,
               url: "/events",
            },
            {
               title: `${t("header.media_center.media_reports")}`,
               url: "/media-center/reports",
            },
            {
               title: `${t("header.media_center.open_access")}`,
               url: "open-books",
            },
            {
               title: `${t("header.media_center.media.media")}`,
               submenu: [
                  {
                     title: `${t("header.media_center.media.photos")}`,
                     url: "/media-center/photos",
                  },
                  {
                     title: `${t("header.media_center.media.videos")}`,
                     url: "/media-center/videos",
                  },
                  {
                     title: `${t("header.media_center.media.audio_books")}`,
                     url: "/media-center/audio_books",
                  },
                  {
                     title: `${t("header.media_center.media.infographic")}`,
                     url: "/media-center/infographics",
                  },
               ],
            },
         ],
      },
      {
         title: `${t("header.darah_store")}`,
         url: URLStore,

         // external_url: "true",
         icon: <i className="fa fa-shopping-cart storeLinkIcon"></i>,
      },
   ])

   const [isFetchingData, setIsFetchingData] = useState(true);
   const [scroll, setScroll] = useState(false);

   const fetchHallDataAndSetSlugForEachPage = async () => {
      try {
         const hallData = await getHallPalace();
         hallData?.map((singleHall) => {
            menuItems[5].submenu[4].submenu.push(singleHall); // will be num 5 after uncomment digital
         });
      } catch (error) {
         console.error(error);
      } finally {
         setIsFetchingData(false);
      }
   };

   const fetchServiceDataAndSetSlugForEachPage = async () => {
      try {
         const serviceData = await getEservicesMenuItems();
         serviceData?.map((singleService) => {
            menuItems[2].submenu.push(singleService);
         });
      } catch (error) {
         console.error(error);
      } finally {
         setIsFetchingData(false);
      }
   };

   // const fetchDigitalContentDataAndSetSlugForEachPage = async () => {
   //    try {
   //       const DigitalContentData = await getDigitalContentMenuItems();
   //       DigitalContentData?.map((singleDigitalContent) => {
   //          menuItems[3].submenu.push(singleDigitalContent);
   //       });
   //    } catch (error) {
   //       console.error(error);
   //    } finally {
   //       setIsFetchingData(false);
   //    }
   // };

   useEffect(() => {
     if(isFetchingData){
      fetchServiceDataAndSetSlugForEachPage();
      fetchHallDataAndSetSlugForEachPage();
      // fetchDigitalContentDataAndSetSlugForEachPage();
     }
   }, []);

   useEffect(() => {
       window.addEventListener("scroll", () => {
           setScroll(window.scrollY > 45);
       });
       return () => {
           window.removeEventListener("scroll", () => {});
       };
   }, [scroll]);

   return (
      <>
         <Container style={{backgroundColor: scroll ? "#425260": "" }} fluid>
            <div className="d-flex justify-content-between align-items-center borderSmScreen animationMenuNav">
              <div style={{width: "35px", height: "56px", display: "flex"}}>
              <Navbar variant="dark" expand="md" className="p-0 hideLightNav position-static" expanded={collapseMenu}>
                  <div className={"ms-2 position-relative d-flex justify-content-center align-items-center"}>
                     <Navbar.Toggle aria-controls="basic-navbar-nav" className="d-flex justify-content-center align-items-center focusBtn">
                     <div className="d-flex justify-content-center align-items-center" style={{height: "60px", width: "30px",}}>
                          {collapseMenu ? <>
                          <i onClick={() => setCollapseMenu(!collapseMenu)} role="button" className={`fas fa-times text-white fs-3`}></i>
                          </> : <>
                          <i onClick={() => setCollapseMenu(!collapseMenu)} role="button" className={`fas fa-bars text-white fs-3`}></i>
                          </>}
                      </div>
                     </Navbar.Toggle>
                  </div>

               <Navbar.Collapse
               style={{
                  top: "55px",
                  right: 0,
                  left: 0,
               }}
                  className={`align-content-center justify-content-center border border-start-0 border-end-0 hideLightNav w-100 position-absolute showNavsLargeScreen`}
                  id="basic-navbar-nav"
               >
                  <div className="position-relative w-100 maxHeightResponsive">
                  <ul className="nav navbar-nav mb-0">
                     {menuItems?.map((menu, index) => {
                        const depthLevel = 0;
                        return (
                           <MenuItems
                              items={menu}
                              key={index}
                              depthLevel={depthLevel}
                              setCollapseMenu={setCollapseMenu}
                           />
                        );
                     })}

                     <li className="menu-items border-bottom favHover">
                        <Link
                        to={process.env.REACT_APP_DARAH_STORE_URL + "favorites"}
                        className="list-group-item d-flex justify-content-start"
                        >
                           <span>{i18n.language == "en" ? "Favorites" : "المفضلة"}</span>
                        </Link>
                     </li>

                     <NavbarIconsMobileScreen setCollapseMenu={setCollapseMenu} /> 
                  </ul>
                  </div>
               </Navbar.Collapse>
               </Navbar>
              </div>

              <div>
                <Link to={"/"}>
                     <img
                        alt="شعار الدارة"
                        style={{maxHeight: "55px"}}
                        // className="darahLogo w-100"
                        src={Links?.logo_image}
                     />
                  </Link>
                </div>

               <div className="d-flex align-items-center">
                  <Link
                     state={{ isPublicSearch: true, searchKey: null }}
                     to="/searchdarah"
                     className="list-group-item d-flex align-items-center"
                  >
                     <i className={`fal fa-search text-white fs-5 fa-fw fw-bold ${i18n.language == "en" ? "me-3" : "ms-3"}`}></i>
                  </Link>

                  <Link
                  to={process.env.REACT_APP_DARAH_STORE_URL + "cart"}
                  className="list-group-item d-flex align-items-center"
                  >
                     {" "}
                     <i class="fal fa-shopping-cart text-white fs-5 fa-fw"></i>
                  </Link>
               </div>
            </div>


         </Container>

         <div style={{backgroundColor: scroll ? "#425260": "" }} className={`newHeaderBackground`}>
         <Container fluid>
         {!collapseMenu ? <>
         <Navbar variant="dark" expand="md" className="p-0 hideHeavyNav w-100">
            <div className={""}>
               <Navbar.Toggle aria-controls="basic-navbar-nav" />
            </div>
            <Navbar.Collapse
               className={`w-100 align-content-center mx-0 justify-content-between`}
               id="basic-navbar-nav"
            >
                <div>
                <Link to={"/"}>
                     <img
                        alt="شعار الدارة"
                        style={{maxHeight: "80px"}}
                        className="darahLogoHeader"
                        src={Links?.logo_image}
                     />
                  </Link>
                </div>
               <ul className="nav navbar-nav mb-0">
                  {menuItems?.map((menu, index) => {
                     const depthLevel = 0;
                     return (
                        <MenuItems
                           items={menu}
                           key={index}
                           depthLevel={depthLevel}
                           setCollapseMenu={setCollapseMenu}
                        />
                     );
                  })}
               </ul>
               <div>
                  <div className={`d-flex justify-content-${  i18n.language == "en" ? "start" : "end"} `}>
                     <NavbarIcons Links={Links} />
                     </div>
               </div>
            </Navbar.Collapse>
         </Navbar>
         </> : <></>}
                  
         </Container>
         </div>

      </>
   );
};

export default NavbarContainer;