// Import Swiper React components
// import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
// import "swiper/css";
// import "swiper/css/navigation";
import "./slider.css";

// import required modules
// import { Autoplay, Navigation, EffectFade } from 'swiper';
import styles from "./slider.module.css";
import { useEffect, useRef, useState } from "react";
// import presentation from "../../../assets/videos/presentationTemp.mp4";
// import scrollDownBtn from "../../../assets/images/mouse-new.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getHomeSections } from "../../../api/services/portal";
import i18n from "../../../i18next";

const PortalSlider = ({ homeSections }) => {
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const navigate = useNavigate();

  const handleFormSubmit = (event) => {
    event.preventDefault();
    navigate("/searchdarah", {
      state: { isPublicSearch: true, searchKey: searchValue },
    });
  };

  // function scrollToSection() {
  //     const mainContainer = document.getElementById('eservice-content');
  //     mainContainer.scrollIntoView({ behavior: 'smooth' });
  // }

  const [isMuted, setIsMuted] = useState(true);
  const videoRef = useRef(null);

  const handleToggleMute = () => {
    const video = videoRef.current;
    video.muted = !isMuted;
    setIsMuted(!isMuted);
  };

  const handleToggleMuteIframe = () => {
    setIsMuted(!isMuted);
  };

  useEffect(() => {
    const handleScroll = () => {
      try {
          const video = videoRef.current;
          const rect = video?.getBoundingClientRect();
          const isVisible = rect?.top < window.innerHeight && rect?.bottom >= 0;
          if (isVisible) {
              video?.play();
          } else {
              video?.pause();
          }
      }catch(err) {
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isMuted]);

  const muteSvg = <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none">
    <g clip-path="url(#clip0_3862_57416)">
    <path d="M15 8.37V7.41C15 4.43 12.93 3.29 10.41 4.87L7.49 6.7C7.17 6.89 6.8 7 6.43 7H5C3 7 2 8 2 10V14C2 16 3 17 5 17H7" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.4102 19.13C12.9302 20.71 15.0002 19.56 15.0002 16.59V12.95" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.81 9.42C19.71 11.57 19.44 14.08 18 16" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21.1501 7.8C22.6201 11.29 22.1801 15.37 19.8301 18.5" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M22 2L2 22" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_3862_57416">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
    </svg>

    const unmuteSvg = <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g clip-path="url(#clip0_3866_57430)">
    <path d="M2.6665 13.3333V18.6666C2.6665 21.3333 3.99984 22.6666 6.6665 22.6666H8.57317C9.0665 22.6666 9.55984 22.8133 9.9865 23.0666L13.8798 25.5066C17.2398 27.6133 19.9998 26.0799 19.9998 22.1199V9.87994C19.9998 5.90661 17.2398 4.38661 13.8798 6.49328L9.9865 8.93328C9.55984 9.18661 9.0665 9.33328 8.57317 9.33328H6.6665C3.99984 9.33328 2.6665 10.6666 2.6665 13.3333Z" stroke="white" stroke-width="2"/>
    <path d="M24 10.6667C26.3733 13.8267 26.3733 18.1734 24 21.3334" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M26.4399 7.33331C30.2933 12.4666 30.2933 19.5333 26.4399 24.6666" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_3866_57430">
    <rect width="32" height="32" fill="white"/>
    </clipPath>
    </defs>
    </svg>


    const getYoutubeVideoId = (url) => {
      const match = url.match(/[?&]v=([^&]+)/);
      return match && match[1];
    };
  
    const youtubeUrl = homeSections?.video?.[0];
    const videoId = youtubeUrl ? getYoutubeVideoId(youtubeUrl) : null;

  return (
    <section className={styles.videoSection}>
      {/*{portalSlider?.sliders?.length > 0 && (*/}
      <span
        role={"button"}
        className={"position-absolute"}
        style=
            {{
                zIndex: 10, 
                top: "85%", 
                right: "10px",
                background:"rgba(255, 255, 255, 0.10)",
                borderRadius:"48px",
                backdropFilter:"blur(10px)",
                padding:"15px"
            }} 
        onClick={videoId ? handleToggleMuteIframe : handleToggleMute}
      >
        {isMuted ? muteSvg : unmuteSvg}
      </span>
      <div className={styles.videoContainer}>
      {videoId ? <>
        {videoId && (
          <iframe
            title={t("media-center-section.open_access_section.separator")}
            className={styles.video}
            width="100%"
            height="auto"
            // src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=${isMuted}&loop=1&controls=0&showinfo=0&modestbranding=1&rel=0`}
            src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=${isMuted}&playlist=${videoId}&loop=1&controls=0&showinfo=0&modestbranding=1&rel=0`}
            frameBorder="0"
            ref={videoRef}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            // allowFullScreen
          ></iframe>
        )}
      </> : <>
        {homeSections?.video?.[0] && (
            <video 
            autoPlay 
            muted 
            loop 
            className={styles.video}
            // aria-hidden="true" 
            ref={videoRef}>
            <source src={"https://darah-dev.4explain.net/uploads/v/v-ar.mp4" } type="video/mp4"/>
                Your browser does not support the video tag.
            </video>
        )}
      </>}
        <div style={{display: videoId ? "none" : "flex"}} className={styles.videoOverlay}>
          <div className={`container ${styles.overlayContent}`}>
            <div className={"d-flex justify-content-center mb-md-3"}>
              {/* <div className={styles.overlayDescription}>
                                <h1 className={`my-3 ${styles.portalTitle} text-center`} abIndex="0">{title}</h1>
                                <p tabIndex="0">
                                    {abstract}
                                </p>
                            </div> */}
              {/* <div className={styles.scrollDownButton} onClick={scrollToSection}>
                                <span className={styles.scrollDownArrow}></span>
                                <img src={scrollDownBtn} alt="أيقونة النزول إلى الأسفل" className={styles.scrollDownImage} />
                            </div> */}
            </div>

            {/* <div>
                            <form className={styles.searchForm} onSubmit={handleFormSubmit}>
                                <div className={styles.searchWrapper + " w-100"}>
                                    <button className={styles.searchButton} type="submit" aria-label={t('portal.search_button')}>
                                        <FaSearch className={styles.searchIcon} />
                                    </button>
                                    <input
                                        className={`${styles.searchInput} w-100`}
                                        id="search"
                                        name="search"
                                        placeholder={(t('digitalcontent.search'))}
                                        required
                                        type="text"
                                        value={searchValue}
                                        onChange={(event) => setSearchValue(event.target.value)}
                                        aria-label={t('digitalcontent.search')}
                                    />
                                </div>
                            </form>
                        </div> */}
          </div>
        </div>
      </div>
      {/*)}*/}
      {/*{portalSlider?.sliders?.length > 0 && (*/}
      {/*<Swiper*/}
      {/*    spaceBetween={30}*/}
      {/*    centeredSlides={true}*/}
      {/*    autoplay={{*/}
      {/*        delay: 4000,*/}
      {/*        disableOnInteraction: false,*/}
      {/*    }}*/}
      {/*    navigation={true}*/}
      {/*    loop={true}*/}
      {/*    rewind={true}*/}
      {/*    modules={[Autoplay, Navigation, EffectFade]}*/}
      {/*    effect="fade"*/}
      {/*    className="mySwiper MainPortalSwiper"*/}
      {/*>*/}
      {/*    {portalSlider?.sliders?.map((slide, index) => (*/}
      {/*        <SwiperSlide key={index} style={{ backgroundImage: `url(${slide?.image?.main})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', backgroundAttachment: 'fixed' }}>*/}
      {/*            <div className="slide-content">*/}
      {/*                {slide.image.logo ? <img src={slide?.image?.logo} alt={"logo"}/> : ''}*/}
      {/*                <div className="slider-title">{slide?.name}</div>*/}
      {/*                <div className="slider-sub-title">{slide?.abstract}</div>*/}
      {/*                {slide.button && (*/}
      {/*                    <a*/}
      {/*                        className={`btn ${styles["btn-primary"]} ${styles.sliderBtn}`}*/}
      {/*                        href={slide?.url}*/}
      {/*                    >*/}
      {/*                        {" "}*/}
      {/*                        {slide?.title_url}*/}
      {/*                        <i className="fad fa-chevron-double-left"></i>{" "}*/}
      {/*                    </a>*/}
      {/*                )}*/}
      {/*            </div>*/}
      {/*        </SwiperSlide>*/}
      {/*    ))}*/}
      {/*</Swiper>*/}
      {/*<section className="search" id="search">*/}
      {/*    <div className="container">*/}
      {/*        <div className="row">*/}
      {/*            <div className="col-xl-12 col-lg-12">*/}
      {/*                <form className="form-group search-box1" onSubmit={handleFormSubmit}>*/}
      {/*                    <div className={"searchInputsContainer"}>*/}
      {/*                        <MultiSelectAll />*/}
      {/*                        <div className="search-wrapper">*/}
      {/*                            <input  className="form-control-search" id="search" name="search"*/}
      {/*                                    placeholder="اكتب كلمة البحث"*/}
      {/*                                    required type="text"*/}
      {/*                                    value={searchValue}*/}
      {/*                                    onChange={(event) => setSearchValue(event.target.value)}*/}
      {/*                            />*/}
      {/*                            <FaSearch className="search-icon" />*/}

      {/*                        </div>*/}

      {/*                        <button className="searchButton" type="submit">*/}
      {/*                            بحــث*/}
      {/*                        </button>*/}
      {/*                    </div>*/}
      {/*                </form>*/}
      {/*            </div>*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*</section>*/}
    </section>
  );
};

export default PortalSlider;
