import { axios, baseURL, config } from "../axios";

export const getHomeSections = () => {
    return axios
        .get(baseURL + "portal/pages/home", config)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
        });
};
